import { SiteContent, SiteMain, MainContentStart, BoundedContent, getRootClassNames, useBrowserClasses, Box, Column, GridContainer, Link, ResponsiveImage, Row } from '@vp/swan'
import { Helmet } from 'react-helmet'
import { OmnisearchResults } from './components/omnisearch/OmnisearchResults'
import { useEffect, useState } from 'react'
import LeftArrowIcon from './assets/images/left_arrow.svg'
import { useTranslation } from 'react-i18next'
import { useStyles } from '@vp/ubik-context'
import styles, { stylesheet } from './App.scss'
import { stylesheet as resStyleSheet } from './components/results/ResultViewer.scss'
import { getHelpCenterUrl } from './utils/url.utils'
import { SEO_DATA } from './node/config/seo.config'
interface Props {
  locale: string
  tenant: string
}
export const App = (props: Props) => {
  const { locale, tenant } = props
  const { t } = useTranslation(tenant)
  const [queryString, setQueryString] = useState<string | null>(null)
  const lng = locale.toLowerCase().replace('-', '_')
  const bodyClassName = getRootClassNames()
  const htmlClassName = useBrowserClasses()
  useStyles(stylesheet, resStyleSheet)

  function getBack () {
    const paths = t(`${props.tenant}:paths.search`, {
      lng,
      returnObjects: true,
    })
    if (
      typeof window !== 'undefined' &&
      window.location &&
      window.location.href
    ) {
      window.location.href = getHelpCenterUrl(paths as Record<string, string>, window.location.pathname)
    }
  }
  useEffect(() => {
    // @ts-ignore
    const locationSearch = typeof window !== 'undefined' ? window.location.search : ''
    const params = new URLSearchParams(locationSearch)

    if (params && params.has('q')) {
      setQueryString(params.get('q') ?? '')
    } else {
      setQueryString('')
    }
  }, [])

  return (
    <>
      <Helmet bodyAttributes={{ class: bodyClassName }} htmlAttributes={{ class: htmlClassName }} defer>
        <title>{SEO_DATA[locale?.toLowerCase()].title || 'Vistaprint Help Center'}</title>
        <style>
          {`
            .site-header-links {
              display: none !important;
            }
          `}
        </style>
      </Helmet>
      <SiteContent>
        <SiteMain>
          <MainContentStart>
            <BoundedContent style={{ maxWidth: '1440px', height: '100%' }}>
              <GridContainer>
                <Row display={{ lg: 'none', md: 'none', sm: 'none' }} marginTop={9} />
                <Row display={{ lg: 'none', md: 'none', xs: 'none', sm: 'block', xl: 'block' }} marginTop={10} />
                <Row display={{ sm: 'none', xs: 'none', md: 'block' }} marginTop={11} />
                <Row>
                  <Column span={4} spanXs={12} spanSm={12} spanMd={4} spanLg={4}>
                    <Row id={styles.backToHelpCenterBox}>
                      <Link href='#' onClick={() => getBack()}>
                        <Box mr='3' style={{ display: 'inline-flex' }}>
                          <ResponsiveImage
                            role='presentation'
                            src={LeftArrowIcon}
                            alt=''
                            height='10px'
                          />
                        </Box>
                        {t(`${props.tenant}:articles.links.back_to_help_center`, {
                          lng,
                        })}
                      </Link>
                    </Row>
                    <Row display={{ lg: 'none', md: 'none', sm: 'none' }} marginTop={8} />
                    <Row display={{ xs: 'none', sm: 'block', md: 'block' }} marginTop={9} />
                  </Column>
                  <Column span={8} spanXs={12} spanSm={12} spanMd={8} spanLg={8}>
                    <Row>
                      <Column
                        span={12}
                        spanXs={12}
                        spanSm={12}
                        spanMd={12}
                        spanLg={12}
                      >
                        {queryString !== null && <OmnisearchResults queryString={queryString} locale={props.locale} tenant={props.tenant} trackingTenant={props.tenant} segmentKey='' />}
                      </Column>
                    </Row>
                  </Column>
                </Row>
                <Row display={{ lg: 'none', md: 'none', sm: 'none' }} marginTop={10} />
                <Row display={{ xs: 'none', sm: 'block' }} marginTop={11} />
              </GridContainer>
            </BoundedContent>
          </MainContentStart>
        </SiteMain>
      </SiteContent>
    </>
  )
}
