import { Metadata } from '@vp/ubik-fragment-types'

export const SEO_DATA: { [x: string]: { title: string } } = {
  'da-dk': {
    title: 'VistaPrint hjælp'
  },
  'de-at': {
    title: 'VistaPrint Hilfe'
  },
  'de-ch': {
    title: 'VistaPrint Hilfe'
  },
  'de-de': {
    title: 'VistaPrint Hilfe'
  },
  'en-au': {
    title: 'VistaPrint Help Centre'
  },
  'en-ca': {
    title: 'VistaPrint Help Center'
  },
  'en-gb': {
    title: 'VistaPrint Help Centre'
  },
  'en-ie': {
    title: 'VistaPrint Help Centre'
  },
  'en-in': {
    title: 'VistaPrint Help Center'
  },
  'en-nz': {
    title: 'VistaPrint Help Centre'
  },
  'en-sg': {
    title: 'VistaPrint Help Centre'
  },
  'en-us': {
    title: 'VistaPrint Help Center'
  },
  'es-es': {
    title: 'Centro de ayuda de VistaPrint'
  },
  'es-us': {
    title: 'Centro de ayuda de VistaPrint'
  },
  'fi-fi': {
    title: 'VistaPrintin ohjekeskus'
  },
  'fr-be': {
    title: "Centre d'assistance VistaPrint"
  },
  'fr-ca': {
    title: 'Centre d’assistance VistaPrint'
  },
  'fr-ch': {
    title: "Centre d'assistance VistaPrint"
  },
  'fr-fr': {
    title: "Centre d'assistance VistaPrint"
  },
  'it-ch': {
    title: 'Centro assistenza VistaPrint'
  },
  'it-it': {
    title: 'Centro assistenza VistaPrint'
  },
  'ja-jp': {
    title: 'ビスタプリント ヘルプセンター'
  },
  'nb-no': {
    title: 'VistaPrint kundeservice'
  },
  'nl-be': {
    title: 'VistaPrint Help'
  },
  'nl-nl': {
    title: 'VistaPrint Help'
  },
  'pt-pt': {
    title: 'Centro de ajuda VistaPrint'
  },
  'sv-se': {
    title: 'VistaPrint Hjälpcenter'
  }
}

export const metadata: Metadata[] = [
  {
    attributes: [
      {
        name: 'robots',
      },
      {
        content: 'noindex, nofollow'
      }
    ]
  },
  {
    attributes: [
      {
        name: 'pageName',
      },
      {
        content: 'Search Page:Help Center'
      }
    ]
  },
  {
    attributes: [
      {
        name: 'pageStage',
      },
      {
        content: 'Customer Care'
      }
    ]
  },
  {
    attributes: [
      {
        name: 'pageSection',
      },
      {
        content: 'Help Center'
      }
    ]
  }
]
