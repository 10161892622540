import i18next from 'i18next'
import React, { PropsWithChildren, ReactElement, useMemo } from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'

export type LocalizationProviderProps = PropsWithChildren<{
  locale: string;
  localizationData: Record<string, any>;
}>

export default function LocalizationProvider (props: LocalizationProviderProps): ReactElement {
  const { locale, localizationData, children } = props
  const i18n = useMemo(() => {
    const i18n = i18next.use(initReactI18next)
    i18n.init({
      resources: localizationData,
      fallbackLng: 'en_us',
      lng: locale?.toLowerCase()?.replace('-', '_') || 'en_us',
      ns: ['vistaprint'],
      defaultNS: 'vistaprint',
      debug: false,
      interpolation: {
        escapeValue: false,
      },
    })
    return i18n
  }, [locale, localizationData])

  return (
    <I18nextProvider i18n={i18n}>
      {children}
    </I18nextProvider>
  )
}
