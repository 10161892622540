import { IdentityProvider, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { App } from './App'
import LocalizationProvider from './components/providers/LocalizationProvider'
import { useEffect } from 'react'
import { initCA } from '@vp/chat-anywhere'
import { isChatEnabled } from './utils/chat.utils'

export interface Props {
  localization: Record<string, any>
}

const createAuthConfig = (culture: string, isDevelopment = false) => ({
  culture,
  developmentMode: isDevelopment,
  options: {
    requireSession: true,
    customText: 'Sign in to see your account',
  },
})
export const Fragment = (props: Props) => {
  const { locale, tenant = 'vistaprint' } = useUserContext()

  useSwanStyleKeys(['core', 'spinner'])
  useEffect(() => {
    if (isChatEnabled(locale)) initCA()
  }, [locale])

  return (
    <LocalizationProvider locale={locale} localizationData={props.localization}>
      <IdentityProvider auth={createAuthConfig(locale)}>
        <App locale={locale} tenant={tenant} />
      </IdentityProvider>
    </LocalizationProvider>
  )
}
