export const getHelpCenterUrl = (paths: Record<string, string>, currentUrl?: string): string => {
  const currUrl = currentUrl || `${window?.location.origin}${window?.location.pathname}`
  const newPathsArray = currUrl.split('/')
  let url = '/'
  let index = 0
  const urlLimit = newPathsArray[1].length === 2 ? 3 : 2
  newPathsArray.forEach(path => {
    if (index < urlLimit && path.trim() !== '') {
      url = `${url}${path}/`
      index++
    }
  })

  // search page
  if (currUrl.indexOf(`/${paths.search}`) !== -1) {
    return `${currUrl.substring(0, currUrl.indexOf(`/${paths.search}`))}`
  } else {
    return `${url}`
  }
}
