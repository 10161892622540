import React, { useEffect } from 'react'
// import TrackingJS from '@vp/tracking'
// import AnalyticsTracking from '../../../analytics/analytics'
import {
  Typography,
  Icon,
  Box,
  FlexBox,
  Divider,
  Link,
  LinkList,
  ListItem,
  Row,
  Column,
} from '@vp/swan'
import { useTranslation } from 'react-i18next'
import { Result, useSearchContext } from '@vp/omnisearch'
import styles, { stylesheet } from './ResultViewer.scss'
import { useStyles } from '@vp/ubik-context'

interface Props {
  tenant: string
  locale: string
  query: string
  segmentKey: string
  trackingTenant: string
}
export const ResultViewer = (props: Props) => {
  const { locale, tenant, query } = props
  const { t } = useTranslation(tenant)
  const lng = locale.toLowerCase().replace('-', '_')
  useStyles(stylesheet)
  const haveResults = t(`${tenant}:search_results.have_results`, {
    lng,
  }).replace('{1}', `"${query}"`)
  const { results } = useSearchContext()

  useEffect(() => {
    // if (props.segmentKey) {
    //   TrackingJS.init(props.segmentKey, {}, props.trackingTenant, locale)
    //   AnalyticsTracking.sendPageTracking({
    //     pageName: 'Search Results Page',
    //     pageSection: 'Help Center',
    //     pageStage: 'Customer Care',
    //     genTrack: 'Titans Squad',
    //     searchResults: results?.HELP_CENTER?.length,
    //     languageLocale: `${locale}`,
    //   })
    // }
  }, [])

  if (results && results.HELP_CENTER?.length) {
    return (
      (
        <Row>
          <Column span={12} spanXs={12}>
            <Row>
              <Typography
                component='h2'
                fontSize='x3large'
                marginLeft='m'
                style={{ fontWeight: '900' }}
              >
                {haveResults}
              </Typography>
            </Row>
            <Row display={{ lg: 'none', md: 'none', sm: 'none' }} marginTop={7} />
            <Row display={{ xs: 'none', sm: 'block' }} marginTop={9} />
          </Column>
          <Column span={12} spanXs={12}>
            {results &&
            Object.entries(results)
              // .filter((taxonomyId) => taxonomyId.includes("HELP_CENTER"))
              .map(([taxonomyId, taxonomyValues], index) => (
                <FlexBox
                  flexDirection='column'
                  alignItems='left'
                  marginBottom={11}
                  key={`result-${index}`}
                  className={styles.helpSection}
                >
                  <Box className={styles.helpCenterLinkContainer}>
                    <LinkList>
                      {taxonomyValues.map((hit, listIndex) => (
                        <ListItem key={hit.objectID}>
                          <Result
                            resultId={hit.objectID}
                            result={hit}
                          >
                            <Link
                              skin='unstyled'
                              href={hit.landingUrl}
                              className={`${styles.helpCenterResultLink} link-with-icon`}
                            >
                              <span>{hit.title}</span>
                              <Icon iconType='arrowRight' />
                            </Link>

                            <Divider />
                          </Result>
                        </ListItem>
                      ))}
                    </LinkList>
                  </Box>
                </FlexBox>
              ))}
          </Column>
        </Row>
      )
    )
  } else {
    return null
  }
}
