import {
  SearchApplication,
  NoResultsSection,
  AwaitAllResultsSection,
  HelpCenterResultsPlugin,
  HelpResultTaxonomy,
} from '@vp/omnisearch'
import { Typography, Spinner, FlexBox } from '@vp/swan'
import { useTranslation } from 'react-i18next'
import { useIdentity } from '@vp/ubik-context'
import { ResultViewer } from '../results/ResultViewer'
interface Props {
  tenant: string
  locale: string
  queryString: string
  trackingTenant: string
  segmentKey: string
}

export const OmnisearchResults = (props: Props) => {
  const { locale, tenant, queryString, segmentKey, trackingTenant } = props
  const { t } = useTranslation(tenant)
  const identity = useIdentity()

  const lng = locale.toLowerCase().replace('-', '_')

  const noResults = t(`${props.tenant}:search_results.no_results`, {
    lng,
  }).replace('{1}', `"${queryString}"`)

  const resultPlugins = [HelpCenterResultsPlugin(locale, 20)]
  return (
    (
      <SearchApplication
        auth={identity.auth}
        applicationName='help-center-page'
        trackExternalClicks
        resultPlugins={resultPlugins}
        locale={locale}
        queryString={queryString}
        searchPlaceholder={t(`${props.tenant}:header.search.placeholder`, {
          lng,
        })}
        // @ts-ignore
        resultDisplayConfiguration={{
          taxonomyOrder: [HelpResultTaxonomy.id],
        }}
      >
        {/* Don't use the flyout */}
        <AwaitAllResultsSection
          loadingView={() => {
            return (
              <FlexBox alignItems='center' justifyContent='center'>
                <Spinner
                  size='super'
                  accessibleText={t(
                `${props.tenant}:search_results.preloader_accessibility_label`,
                {
                  lng,
                }
                  )}
                />
              </FlexBox>
            )
          }}
        >
          <ResultViewer
            tenant={tenant}
            locale={locale}
            trackingTenant={trackingTenant}
            query={queryString}
            segmentKey={segmentKey}
          />
          <NoResultsSection>
            <Typography component='h1' fontSize='x3large' style={{ fontWeight: '900' }}>
              {noResults}
            </Typography>{' '}
          </NoResultsSection>
        </AwaitAllResultsSection>
        {/* ... this is where the rest of your page implementation would go... */}
      </SearchApplication>
    )
  )
}
